body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "RobotoReg";
  src: local("RobotoReg"), url(../assets/fonts/Roboto-Regular.ttf) format("ttf");
}

@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"), url(../assets/fonts/Roboto-Bold.ttf) format("ttf");
}

.anim750 {
  transition: all 750ms ease-in-out;
}

#Awesome {
  position: relative;
  width: 280px;
  height: 280px;
  margin: 0 auto;

  backface-visibility: hidden;
}

#Awesome .sticky {
  transform: rotate(45deg);
}

#Awesome:hover .sticky {
  transform: rotate(10deg);
}

#Awesome .sticky {
  position: absolute;
  top: 0;
  left: 0;
  width: 280px;
  height: 280px;
}

#Awesome .reveal .circle {
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.15);

  font-family: "helvetica neue", arial;
  font-weight: 200;
  line-height: 140px;
  text-align: center;

  cursor: pointer;
}

#Awesome .reveal .circle {
  background: #fafafa;
}

#Awesome .circle_wrapper {
  position: absolute;
  width: 280px;
  height: 280px;
  left: 0px;
  top: 0px;
  overflow: hidden;
}

#Awesome .circle {
  position: absolute;
  width: 240px;
  height: 240px;
  margin: 20px;

  border-radius: 999px;
}

#Awesome .back {
  height: 10px;
  top: 30px;
}

#Awesome:hover .back {
  height: 190px;
  top: 210px;
}

#Awesome .back .circle {
  margin-top: -130px;
  background-color: #fbec3f;

  background-image: -webkit-linear-gradient(
    bottom,
    rgba(251, 236, 63, 0),
    rgba(255, 255, 255, 0.8)
  );
}

#Awesome:hover .back .circle {
  margin-top: -50px;
}

#Awesome .front {
  height: 250px;
  bottom: 0;
  top: auto;

  -webkit-box-shadow: 0 -140px 20px -140px rgba(0, 0, 0, 0.3);
}

#Awesome:hover .front {
  height: 70px;

  -webkit-box-shadow: 0 -60px 10px -60px rgba(0, 0, 0, 0.1);
}

#Awesome .front .circle {
  margin-top: -10px;
  background: #fbec3f;

  background-image: -webkit-linear-gradient(
    bottom,
    rgba(251, 236, 63, 0) 75%,
    #f7bb37 95%
  );
  background-image: -moz-linear-gradient(
    bottom,
    rgba(251, 236, 63, 0) 75%,
    #f7bb37 95%
  );
  background-image: linear-gradient(
    bottom,
    rgba(251, 236, 63, 0) 75%,
    #f7bb37 95%
  );
}

#Awesome h4 {
  font-family: "helvetica neue", arial;
  font-weight: 200;
  text-align: center;
  position: absolute;
  width: 280px;
  height: 240px;
  line-height: 140px;

  transition: opacity 50ms linear 400ms;
}

#Awesome:hover h4 {
  opacity: 0;

  transition: opacity 50ms linear 300ms;
}

#Awesome:hover .front .circle {
  margin-top: -90px;
  background-color: #e2d439;
  background-position: 0 100px;
}
